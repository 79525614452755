import sanitizeHtml from "sanitize-html";

export default function sanitizeHTML(input: string): string {
    return sanitizeHtml(input, {
        allowedTags: [
            'b', 'i', 'em', 'strong', 'u', 'p', 'ul', 'ol', 'li', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
            'a', 'img', 'code', 'pre', 'table', 'tr', 'th', 'td', 'hr', 'blockquote', 'span', 'div', 'br'
        ],
        allowedAttributes: {
            a: ['href', 'title', 'target'],
            img: ['src', 'alt', 'width', 'height'],
            '*': ['class', 'style', 'id']
        },
        allowedSchemes: ['http', 'https', 'mailto'],
        allowedIframeHostnames: []
    });
}
